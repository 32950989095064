import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import ResourcesPage from '../ui/pages/ResourcesPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const Resources = props => (
  <>
    <HelmetComponent
      title={metaTags.indexTitle}
      description={metaTags.resources}
      page="resources"
    />
    <AppWrapper {...props}>
      <ResourcesPage />
    </AppWrapper>
  </>
)

export default Resources
